import './App.css';
import Typography from '@mui/material/Typography';

function App() {
  return (
    <div className="App">
      <Typography variant="h3" component="h2">
        Knowledge Portfolio
      </Typography>
    </div>
  );
}

export default App;
